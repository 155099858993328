/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
    this.backgroundColors = {
      "Provider": "#008BB0",
      "SkinLab": "#967B4E",
      "Flagship": "#000000"
    };
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 20,
    height= 27,
    index = '',
    profile = ''
  } = {}) {
    const svg = 
      `<svg width="${width}" height="${height}" viewBox="0 0 34 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23 17C23 20.3135 20.3135 23 17 23C13.6865 23 11 20.3135 11 17C11 13.6865 13.6865 11 17 11C20.3135 11 23 13.6865 23 17Z" fill="${backgroundColor}"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 45.5C17 45.5 34 34 34 17C34 7.61133 26.3887 0 17 0C7.61133 0 0 7.61133 0 17C0 34 17 45.5 17 45.5ZM28 17C28 23.0752 23.0752 28 17 28C10.9248 28 6 23.0752 6 17C6 10.9248 10.9248 6 17 6C23.0752 6 28 10.9248 28 17Z" fill="${backgroundColor}"/>
      </svg>`;
    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.backgroundColors[profile.c_locationType] ? this.backgroundColors[profile.c_locationType] : this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 24,
      height: 28,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.backgroundColors[profile.c_locationType] ? this.backgroundColors[profile.c_locationType] : this.defaultPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 28,
      height: 34,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.backgroundColors[profile.c_locationType] ? this.backgroundColors[profile.c_locationType] : this.defaultPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 28,
      height: 34,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
